.nos-services {
    background-color: white;
}

.commande {
    font-weight: bold;
    color: #d96220;
}

.nos-prestations {
    background-color: #ebdfc9;
    padding: 30px;
    text-align: center;
}

.each-prestation {
    display: flex;
    justify-content: space-around;
}

.section-prestation {
    text-align: center;
    background-color: white;
    padding: 60px 0 60px 0;
    color: #d96220;
}

.section-prestation button {
    border: none;
    font-size: 15px;
    background-color: #d96220;
    border-radius: 20px;
    padding: 10px;
    color: #5C3321;
    margin: 15px;
}

.menus {
    margin: 40px 200px 40px 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.menu-description {
    background-color: #ebdfc9;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;
}

.each-menu {
    display: flex;
    justify-content: center;
    height: 600px;
    overflow: hidden;
}

.each-menu img {
    width: 50%;
    height: auto;
    object-fit: cover;
}

.each-menu p {
    width: 90%;
}

.carroussel {
    padding: 50px;
}

.carroussel h4 {
    text-align: center;
    margin-bottom: 60px;
}

.suggestions {
    display: flex;
    align-items: center;
}

.presentation-suggestions {
    margin-left: 40px;
    margin-top: -15px;
}

.presentation-suggestions li {
    list-style-type: none;
}

.presentation-suggestions ul {
    padding-left: 0;
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .menus {
        margin: 0 30px 0 30px;
    }

    .carroussel {
        padding: 50px 10px;
    }

    .carroussel-container img {
        width: 450px;
    }

    .presentation-suggestions { 
        margin-left: 15px;
    }

}

@media screen and (max-width: 767px) {

    .each-prestation {
        flex-direction: column;
        margin-top: 20px;
    }

    .section-prestation {
        padding: 0 15px 0 15px;
    }

    .menus {
        margin: 20px;
    }

    .each-menu {
        height: 100%;
        flex-direction: column;
    }

    .menu-description {
        width: 100%;
        margin-top: 20px;
    }

    .each-menu img {
        display: none;
    }

    .carroussel {
        padding: 0;
    }

    .carroussel h4 {
        margin: 60px 0 0 10px;
    }

    .suggestions {
        flex-direction: column;
    }

    .presentation-suggestions {
        margin: 15px;
        text-align: center;
    }

}