.carroussel-container {
    position: relative; 
  }
  
  .carroussel-container img {
    width: 500px;
    height: 500px;
    object-fit: cover;
  }
  
  .carroussel-buttons {
    position: absolute;
    top: 50%;
    left: 20px;
    right: 20px;
    display: flex;
    justify-content: space-between;
  }

  .carroussel-buttons img {
    height: 40px;
    width: 20px;
  }

  @media screen and (max-width: 767px) {

    .carroussel-container img {
      height: 300px;
      width: 300px;
    }

    .carroussel-buttons img {
      height: 20px;
      width: 10px;
    }

    .carroussel-container {
      margin-top: 20px;
    }

  }