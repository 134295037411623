.header {
    display: flex;
    justify-content: space-between;
    background-color: white;
    font-family: 'Josefin Sans', sans-serif;
}

.logo-header {
    width: 250px;
}

.menu-header {
    display: flex;
    align-items: center;
}

.menu-header ul {
    display: flex;
    list-style-type: none;
}

.menu-header li {
    margin-right: 20px;
    font-size: 20px;
}

.menu-header a {
    text-decoration: none;
    color: #5C3321;
}

@media screen and (max-width: 767px) {

    .header {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .menu-header li {
        font-size: 13px;
        margin-bottom: 8px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .menu-header ul {
        padding-left: 0;
    }

    .logo-header {
        width: 200px;
    }

}