.footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #5C3321;
}

.mentions-legales-button {
    text-decoration: underline;
    background-color: transparent;
    color: #5C3321;
    padding-bottom: 10px;
}

.footer-icon {
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: transparent;
    font-size: 30px;
    margin: 20px 0 30px 0;
}

.footer-a {
    text-decoration: none;
    background-color: #5C3321;
    border-radius: 35%;
    padding: 15px;
    color: #ebdfc9;
}

.each-footer {
    width: 10%;
    font-size: 15px;
}

.each-footer p {
    padding-top: 15px;
    font-weight: bold;
}

@media screen and (max-width: 767px) {

    .each-footer {
        width: 35%;
    }

    .footer a {
        margin: 0;
    }

    .footer p {
        font-size: 10px;
    }

    .footer-icon {
        margin: 20px 10px 20px 10px;
    }

    .mentions-légales {
        font-size: 7px;
    }

}


