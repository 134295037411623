.image-prestation {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border: 4px solid #d96220;
}

.prestation {
    text-align: center;
    max-width: 25%;
}

@media screen and (max-width: 767px) {

    .prestation {
        max-width: 100%;
        margin-top: 30px;
    }

}