.galerie {
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.image-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.image-description {
    position: absolute;
    text-align: left;
    bottom: -20px;
    left: 0;
    color: #fff;
    padding: 5px;
    font-size: 20px;
    width: 90%;
    word-wrap: break-word;
    background-color: rgba(217, 98, 32, 0.7);
    border-radius: 0 20px 0 0;
  }

.filter-buttons {
    margin-top: 40px;
    margin-bottom: 40px;
}

.filter-buttons button {
    font-size: 15px;
    background-color: #d96220;
    border-radius: 20px;
    border: none;
    padding: 10px;
    color: #5C3321;
    margin: 15px;
    width: 100px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.filter-buttons button:hover {
    transform: scale(1.1);
}

.plat-galerie {
    width: 300px;
    height: 300px;
    object-fit: cover;
}

.galerie-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

@media screen and (max-width: 767px) {

    .filter-buttons button {
        padding: 5px;
        margin: 10px;
        width: 70px;
    }

    .filter-buttons {
        margin: 40px;
    }

}