.description-contacts {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
}

.plat-contact {
    width: 400px;
    height: auto;
    border-radius: 0 50% 0 0;
}

.intro-contact {
    margin: 40px 200px 40px 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.intro-contact li {
    list-style-type: none;
}

.form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.form-group label {
    width: 48%;
    text-align: left;
    margin-right: 2%;
}

.form-group input,
.form-group textarea {
    width: 48%; 
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.submit-button {
    background-color: #d96220;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.informations {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 90px 250px 70px 250px;
    font-size: 20px;
    background-color: white;
}

.each-informations {
    background-color: #d96220;
    padding: 15px 10px 10px 10px;
    width: 300px;
    text-align: center;
    border-radius: 50px;
    border: 2px solid #d96220;
    color: black;
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

.each-informations li {
    list-style-type: none;
    text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .intro-contact {
        margin: 40px;
    }

    .informations {
        padding: 0 0 70px 0;
    }

}

@media screen and (max-width: 767px) {

.description-contacts {
    background-color: #ebdfc9;
}
    
.intro-contact {
    margin: 15px;
}

.informations {
    padding: 30px 0 0 0;
}

}